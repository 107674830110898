import { useState, useEffect, useMemo } from 'react';
import { 
  UsersIcon, 
  ServerIcon,
  ChartBarIcon,
  TrashIcon,
  LockClosedIcon,
  LockOpenIcon
} from '@heroicons/react/24/outline';
import { PageContainer } from './PageContainer';
import { useLocale } from '../contexts/LocaleContext';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { 
  ref,
  get,
  update,
  remove,
  query,
  orderByChild,
  equalTo
} from 'firebase/database';
import { ADMIN_UID } from '../constants/auth';
import { Dialog } from './Dialog';
import { cacheManager } from '../utils/cache';
import { toast } from 'react-hot-toast';

const StatsCard = ({ title, value, icon: Icon }) => (
  <div className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg border border-gray-100 p-3 sm:p-6">
    <div className="flex items-center gap-2 sm:gap-4">
      <div className="flex-shrink-0">
        <Icon className="w-4 h-4 sm:w-5 sm:h-5 text-blue-500" />
      </div>
      <div>
        <h3 className="text-sm sm:text-lg font-semibold text-gray-900 mb-0.5">{title}</h3>
        <p className="text-xl sm:text-3xl font-bold text-blue-600">{value}</p>
      </div>
    </div>
  </div>
);

const formatDate = (dateString) => {
  if (!dateString) return '-';
  
  // Преобразуем строку ddmmyyyy в формат dd.mm.yyyy
  const day = dateString.substring(0, 2);
  const month = dateString.substring(2, 4);
  const year = dateString.substring(4, 8);
  
  return `${day}.${month}.${year}`;
};

const UserCard = ({ user, onUpdatePlan, onToggleBlock, onDeletePosts, onDeletePost, onShowDeleteConfirm }) => {
  const { t } = useLocale();
  
  const formatPostsCount = (count) => {
    if (count === 0) {
      return t('admin.sections.users.table.noPosts');
    }
    return t('admin.sections.users.table.postsCount').replace('{count}', count);
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="p-2 sm:p-6">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-2 sm:gap-4 mb-3 sm:mb-4">
          <div>
            <h3 className="text-base sm:text-lg font-semibold text-gray-900 mb-0.5 sm:mb-1">{user.email}</h3>
            <p className="text-xs sm:text-sm text-gray-500">UID: {user.uid}</p>
          </div>
          <div className="flex items-center gap-2">
            <span className={`inline-flex items-center px-1.5 sm:px-2.5 py-0.5 rounded-full text-xs font-medium ${
              user.plan === 'Pro' 
                ? 'bg-green-100 text-green-800'
                : 'bg-gray-100 text-gray-800'
            }`}>
              {user.plan}
            </span>
            <span className="text-xs sm:text-sm text-gray-500">
              {formatPostsCount(user.posts.length)}
            </span>
          </div>
        </div>

        <div className="flex flex-wrap items-center gap-2 sm:gap-3">
          <select
            value={user.plan}
            onChange={(e) => onUpdatePlan(user.uid, e.target.value)}
            className="text-xs sm:text-sm border rounded-lg px-2 sm:px-3 py-1 sm:py-1.5 min-w-[80px] sm:min-w-[100px]"
          >
            <option value="Free">Free</option>
            <option value="Pro">Pro</option>
          </select>

          <button
            onClick={() => onToggleBlock(user.uid)}
            className={`inline-flex items-center gap-1 px-2 sm:px-3 py-1 sm:py-1.5 rounded-lg text-xs sm:text-sm flex-shrink-0 ${
              user.isBlocked
                ? 'bg-red-50 text-red-600 hover:bg-red-100'
                : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
            }`}
          >
            {user.isBlocked ? (
              <>
                <LockClosedIcon className="w-3 h-3 sm:w-4 sm:h-4" />
                <span>{t('admin.sections.users.actions.unblock')}</span>
              </>
            ) : (
              <>
                <LockOpenIcon className="w-3 h-3 sm:w-4 sm:h-4" />
                <span>{t('admin.sections.users.actions.block')}</span>
              </>
            )}
          </button>

          <button
            onClick={() => onDeletePosts(user.uid)}
            className="inline-flex items-center gap-1 px-2 sm:px-3 py-1 sm:py-1.5 rounded-lg text-xs sm:text-sm text-red-600 hover:bg-red-50 flex-shrink-0"
          >
            <TrashIcon className="w-3 h-3 sm:w-4 sm:h-4" />
            <span>{t('admin.sections.users.actions.deletePosts')}</span>
          </button>
        </div>
      </div>

      {user.posts.length > 0 && (
        <div className="border-t border-gray-100 mt-2 sm:mt-4">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-2 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('admin.sections.users.table.title')}
                  </th>
                  <th className="px-2 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('admin.sections.users.table.ip')}
                  </th>
                  <th className="hidden sm:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('admin.sections.users.table.created')}
                  </th>
                  <th className="hidden sm:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('admin.sections.users.table.lastUpdate')}
                  </th>
                  <th className="px-2 sm:px-6 py-2 sm:py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('admin.sections.users.table.actions')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {user.posts.map((post) => (
                  <tr key={post.id}>
                    <td className="px-2 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-xs sm:text-sm font-medium text-gray-900">
                      {post.title}
                    </td>
                    <td className="px-2 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-xs sm:text-sm text-gray-500">
                      {post.ip}
                    </td>
                    <td className="hidden sm:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(post.createdAt)}
                    </td>
                    <td className="hidden sm:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(post.lastUpdate)}
                    </td>
                    <td className="px-2 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-right text-xs sm:text-sm">
                      <button
                        onClick={() => onShowDeleteConfirm({ userId: user.uid, postId: post.id })}
                        className="text-red-600 hover:text-red-900"
                      >
                        <TrashIcon className="w-4 h-4 sm:w-5 sm:h-5" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export const AdminPage = () => {
  const { t } = useLocale();
  const { user: currentUser } = useAuth();
  const [users, setUsers] = useState([]);
  const [cachedUsers, setCachedUsers] = useState({});
  const [stats, setStats] = useState({ totalPosts: 0, totalUsers: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [showEmptyUsers, setShowEmptyUsers] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // Функция для загрузки данных пользователей
  const fetchUsersData = async () => {
    const usersRef = ref(db, 'users');
    const usersSnapshot = await get(usersRef);
    const usersData = [];
    let totalPosts = 0;

    if (usersSnapshot.exists()) {
      for (const [uid, userData] of Object.entries(usersSnapshot.val())) {
        const userPostsRef = ref(db, `user-posts/${uid}`);
        const userPostsSnapshot = await get(userPostsRef);
        
        const posts = [];
        if (userPostsSnapshot.exists()) {
          const userPosts = userPostsSnapshot.val();
          for (const [postId, postData] of Object.entries(userPosts)) {
            const postRef = ref(db, `posts/${postId}`);
            const postSnapshot = await get(postRef);
            
            if (postSnapshot.exists()) {
              const postVal = postSnapshot.val();
              posts.push({
                id: postId,
                title: postVal.title,
                ip: postVal.ip || '',
                createdAt: postData.created || null,
                lastUpdate: postData.updated || postData.created || null
              });
            }
          }
        }

        totalPosts += posts.length;

        usersData.push({
          uid,
          email: userData.email || '',
          plan: (userData.level || 'Free').charAt(0).toUpperCase() + (userData.level || 'free').slice(1).toLowerCase(),
          isBlocked: userData.isBlocked || false,
          posts
        });
      }
    }

    setStats({
      totalUsers: usersData.length,
      totalPosts
    });

    return usersData;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser) return;

      try {
        setLoading(true);
        
        // Пробуем получить данные из кэша
        const cachedUsers = await cacheManager.getCachedData('admin_users');
        if (cachedUsers) {
          setUsers(cachedUsers);
          setCachedUsers(prevCache => {
            const newCache = { ...prevCache };
            cachedUsers.forEach(user => {
              newCache[user.uid] = user;
            });
            return newCache;
          });
          setLoading(false);
          return;
        }

        // Если кэша нет или он устарел, загружаем данные
        const usersData = await fetchUsersData();
        setUsers(usersData);
        
        // Кэшируем полученные данные
        try {
          cacheManager.setCachedData('admin_users', usersData);
        } catch (cacheError) {
          console.error('Cache write error:', cacheError);
          toast.error(t('errors.cache.write'));
        }
        
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
        toast.error(t('errors.loading.users'));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser]);

  useEffect(() => {
    if (users.length > 0) {
      setCachedUsers(prevCache => {
        const newCache = { ...prevCache };
        users.forEach(user => {
          newCache[user.uid] = user;
        });
        return newCache;
      });
    }
  }, [users]);

  const handleUpdatePlan = async (uid, newPlan) => {
    try {
      const userRef = ref(db, `users/${uid}`);
      await update(userRef, { level: newPlan.toLowerCase() });
      
      setUsers(users.map(user => 
        user.uid === uid ? { ...user, plan: newPlan } : user
      ));
    } catch (err) {
      console.error('Error updating plan:', err);
    }
  };

  const handleToggleBlock = async (uid) => {
    try {
      const user = users.find(u => u.uid === uid);
      const userRef = ref(db, `users/${uid}`);
      const blockedRef = ref(db, `blocked_users/${uid}`);
      const newBlockedStatus = !user.isBlocked;
      
      if (newBlockedStatus) {
        // Блокируем пользователя
        await update(blockedRef, {
          uid,
          email: user.email,
          blockedAt: new Date().toISOString()
        });
      } else {
        // Разблокируем пользователя
        await remove(blockedRef);
      }
      
      await update(userRef, { isBlocked: newBlockedStatus });
      
      setUsers(users.map(user => 
        user.uid === uid ? { ...user, isBlocked: newBlockedStatus } : user
      ));
      toast.success(newBlockedStatus ? t('notifications.admin.userBlocked') : t('notifications.admin.userUnblocked'));
    } catch (err) {
      console.error('Error toggling block status:', err);
      toast.error(t('errors.admin.blockUser'));
    }
  };

  const handleDeletePosts = async (uid) => {
    if (!showDeleteConfirm || showDeleteConfirm.userId !== uid) return;

    try {
      const userPosts = users.find(u => u.uid === uid).posts;
      
      // Сначала удаляем все посты из DO
      for (const post of userPosts) {
        try {
          const response = await fetch('/delhost', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              hostname: post.title,
              uid: uid
            })
          });

          const result = await response.json(); // Парсим JSON ответ

          if (!response.ok) {
            throw new Error(result.message || `Failed to delete host ${post.title} from DO`);
          }
        } catch (error) {
          setError(`Failed to delete host ${post.title} from Digital Ocean: ${error.message}`);
          setShowDeleteConfirm(null);
          return;
        }
      }
      
      // Если все посты успешно удалены из DO, удаляем их из Firebase
      try {
        await Promise.all(userPosts.map(async (post) => {
          await remove(ref(db, `posts/${post.id}`));
          await remove(ref(db, `user-posts/${uid}/${post.id}`));
        }));
      } catch (error) {
        setError(`Posts were deleted from Digital Ocean but failed to delete from database: ${error.message}`);
        setShowDeleteConfirm(null);
        return;
      }
      
      // Обновляем UI и кэш
      const updatedUsers = users.map(user => 
        user.uid === uid ? { ...user, posts: [] } : user
      );
      
      setUsers(updatedUsers);
      
      // Обновляем кэш
      try {
        cacheManager.setCachedData('admin_users', updatedUsers);
        setCachedUsers(prevCache => {
          const newCache = { ...prevCache };
          updatedUsers.forEach(user => {
            newCache[user.uid] = user;
          });
          return newCache;
        });
      } catch (cacheError) {
        console.error('Cache update error:', cacheError);
        toast.error(t('errors.cache.write'));
      }
      
      setStats(prev => ({
        ...prev,
        totalPosts: prev.totalPosts - userPosts.length
      }));

      toast.success(t('notifications.admin.userDeleted'));
      setShowDeleteConfirm(null);
    } catch (err) {
      console.error('Error in delete operation:', err);
      toast.error(t('errors.admin.deleteUser'));
      setShowDeleteConfirm(null);
    }
  };

  const handleDeletePost = async (uid, postId) => {
    if (!showDeleteConfirm || showDeleteConfirm.userId !== uid || showDeleteConfirm.postId !== postId) return;

    try {
      const post = users.find(u => u.uid === uid).posts.find(p => p.id === postId);
      
      // Сначала удаляем из DO
      try {
        const response = await fetch('/delhost', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            hostname: post.title,
            uid: uid
          })
        });

        const result = await response.json();

        if (!response.ok) {
          throw new Error(result.message || 'Failed to delete host from Digital Ocean');
        }
      } catch (error) {
        setError(`Failed to delete host from Digital Ocean: ${error.message}`);
        setShowDeleteConfirm(null);
        return;
      }

      // Если успешно удалено из DO, удаляем из Firebase
      try {
        await remove(ref(db, `posts/${postId}`));
        await remove(ref(db, `user-posts/${uid}/${postId}`));
      } catch (error) {
        setError(`Host was deleted from Digital Ocean but failed to delete from database: ${error.message}`);
        setShowDeleteConfirm(null);
        return;
      }
      
      // Обновляем UI и кэш
      const updatedUsers = users.map(user => {
        if (user.uid === uid) {
          return {
            ...user,
            posts: user.posts.filter(p => p.id !== postId)
          };
        }
        return user;
      });
      
      setUsers(updatedUsers);
      
      // Обновляем кэш
      try {
        cacheManager.setCachedData('admin_users', updatedUsers);
        setCachedUsers(prevCache => {
          const newCache = { ...prevCache };
          updatedUsers.forEach(user => {
            newCache[user.uid] = user;
          });
          return newCache;
        });
      } catch (cacheError) {
        console.error('Cache update error:', cacheError);
        toast.error(t('errors.cache.write'));
      }
      
      setStats(prev => ({
        ...prev,
        totalPosts: prev.totalPosts - 1
      }));

      toast.success('Host was successfully deleted');
      setShowDeleteConfirm(null);
    } catch (err) {
      console.error('Error in delete operation:', err);
      setError(`Operation failed: ${err.message}`);
      setShowDeleteConfirm(null);
    }
  };

  // Оптимизация рендеринга списка пользователей
  const sortedUsers = useMemo(() => {
    const users = Object.values(cachedUsers)
      .sort((a, b) => {
        // Сортировка по последней активности или другому критерию
        return new Date(b.lastLogin || 0) - new Date(a.lastLogin || 0);
      });
      
    // Обновляем статистику при изменении списка пользователей
    const totalPosts = users.reduce((sum, user) => sum + (user.posts?.length || 0), 0);
    setStats({
      totalUsers: users.length,
      totalPosts
    });
      
    return users;
  }, [cachedUsers]);

  // Оптимизация фильтрации
  const filteredUsers = useMemo(() => {
    return sortedUsers
      // Сначала фильтруем по наличию хостов
      .filter(user => showEmptyUsers || user.posts.length > 0)
      // Затем фильтруем по поисковому запросу
      .filter(user => {
        if (!searchQuery) return true;
        return user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
               user.displayName?.toLowerCase().includes(searchQuery.toLowerCase());
      });
  }, [sortedUsers, searchQuery, showEmptyUsers]);

  if (loading) {
    return (
      <PageContainer>
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      </PageContainer>
    );
  }

  if (error) {
    return (
      <PageContainer>
        <div className="bg-red-50 text-red-600 p-4 rounded-lg">
          {t('errors.loading')}: {error}
        </div>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <div className="grid gap-4 sm:gap-6">
        {/* Статистика */}
        <section className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg border border-gray-100 p-3 sm:p-6">
          <div className="flex items-center gap-2 mb-3 sm:mb-6">
            <ChartBarIcon className="w-5 h-5 sm:w-6 sm:h-6 text-blue-500" />
            <h2 className="text-base sm:text-xl font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
              {t('admin.sections.stats.title')}
            </h2>
          </div>
          <p className="text-xs sm:text-base text-gray-600 mb-3 sm:mb-6">
            {t('admin.sections.stats.description')}
          </p>
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 sm:gap-6">
            <StatsCard 
              title={t('admin.sections.stats.cards.totalPosts')} 
              value={stats.totalPosts} 
              icon={ServerIcon} 
            />
            <StatsCard 
              title={t('admin.sections.stats.cards.totalUsers')} 
              value={stats.totalUsers} 
              icon={UsersIcon} 
            />
          </div>
        </section>

        {/* Пользователи */}
        <section className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg border border-gray-100 p-3 sm:p-6">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-4 sm:mb-6">
            <div className="flex items-center gap-2">
              <UsersIcon className="w-5 h-5 sm:w-6 sm:h-6 text-blue-500" />
              <h2 className="text-lg sm:text-xl font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                {t('admin.sections.users.title')}
              </h2>
            </div>
            
            {/* Переключатель */}
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-600">
                {t('admin.sections.users.showEmpty')}
              </span>
              <button
                onClick={() => setShowEmptyUsers(!showEmptyUsers)}
                className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
                  showEmptyUsers ? 'bg-blue-600' : 'bg-gray-200'
                }`}
              >
                <span
                  className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                    showEmptyUsers ? 'translate-x-6' : 'translate-x-1'
                  }`}
                />
              </button>
            </div>
          </div>

          <div className="space-y-4">
            {filteredUsers.map((user) => (
              <div key={user.uid} className="transform hover:-translate-y-1 transition-all duration-300">
                <UserCard
                  user={user}
                  onUpdatePlan={handleUpdatePlan}
                  onToggleBlock={handleToggleBlock}
                  onDeletePosts={() => setShowDeleteConfirm({ userId: user.uid })}
                  onDeletePost={handleDeletePost}
                  onShowDeleteConfirm={setShowDeleteConfirm}
                />
              </div>
            ))}
          </div>
        </section>
      </div>
      
      {showDeleteConfirm && (
        <Dialog
          title={showDeleteConfirm.postId ? "Delete Post" : "Delete All Posts"}
          message={showDeleteConfirm.postId 
            ? "Are you sure you want to delete this post?" 
            : "Are you sure you want to delete all posts for this user?"
          }
          confirmLabel="Delete"
          cancelLabel="Cancel"
          onConfirm={() => showDeleteConfirm.postId 
            ? handleDeletePost(showDeleteConfirm.userId, showDeleteConfirm.postId)
            : handleDeletePosts(showDeleteConfirm.userId)
          }
          onCancel={() => setShowDeleteConfirm(null)}
        />
      )}
    </PageContainer>
  );
}; 