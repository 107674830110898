import { useState, useEffect, useMemo } from 'react';
import { 
  ClipboardIcon,
  ExclamationTriangleIcon,
  UserCircleIcon,
  CreditCardIcon,
  KeyIcon,
  ServerStackIcon,
  CheckBadgeIcon,
  EyeIcon,
  EyeSlashIcon
} from '@heroicons/react/24/outline';
import { PageContainer } from './PageContainer';
import { useLocale } from '../contexts/LocaleContext';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { ref, get, update } from 'firebase/database';

export const AccountPage = () => {
  const { t } = useLocale();
  const { user: authUser } = useAuth();
  const [showConfirmRevoke, setShowConfirmRevoke] = useState(false);
  const [isTokenCopied, setIsTokenCopied] = useState(false);
  const [showFullToken, setShowFullToken] = useState(false);
  const [userData, setUserData] = useState(null);
  const [cachedUserData, setCachedUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!authUser?.uid) return;

      try {
        const userRef = ref(db, `users/${authUser.uid}`);
        const snapshot = await get(userRef);
        
        if (snapshot.exists()) {
          const data = snapshot.val();
          const planLevel = (data.level || 'free').charAt(0).toUpperCase() + (data.level || 'free').slice(1).toLowerCase();
          
          setUserData({
            plan: planLevel,
            token: data.token || '',
            hostsLimit: planLevel === 'Pro' ? 100 : 3,
            hostsUsed: 0
          });
        }

        // Получаем количество хостов пользователя
        const userPostsRef = ref(db, `user-posts/${authUser.uid}`);
        const postsSnapshot = await get(userPostsRef);
        
        if (postsSnapshot.exists()) {
          const hostsCount = Object.keys(postsSnapshot.val()).length;
          setUserData(prev => ({
            ...prev,
            hostsUsed: hostsCount
          }));
        }

        setLoading(false);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchUserData();
  }, [authUser?.uid]);

  // Кэширование данных пользователя
  useEffect(() => {
    if (userData) {
      setCachedUserData(prevCache => ({
        ...prevCache,
        ...userData
      }));
    }
  }, [userData]);

  // Оптимизация рендеринга с помощью useMemo
  const sortedUserHosts = useMemo(() => {
    if (!cachedUserData.hosts) return [];
    return Object.values(cachedUserData.hosts)
      .sort((a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate));
  }, [cachedUserData.hosts]);

  const generateSecureToken = () => {
    const array = new Uint8Array(32); // 256 бит
    crypto.getRandomValues(array);
    return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
  };

  const handleRevokeToken = async () => {
    try {
      const newToken = generateSecureToken();
      
      // Обновляем токен в таблице users
      const userRef = ref(db, `users/${authUser.uid}`);
      await update(userRef, { token: newToken });
      
      // Получаем все записи пользователя из user-posts
      const userPostsRef = ref(db, `user-posts/${authUser.uid}`);
      const userPostsSnapshot = await get(userPostsRef);
      
      if (userPostsSnapshot.exists()) {
        const postIds = Object.keys(userPostsSnapshot.val());
        
        // Обновляем токен в каждой записи в posts и user-posts
        const updates = {};
        postIds.forEach(postId => {
          updates[`/posts/${postId}/token`] = newToken;
          updates[`/user-posts/${authUser.uid}/${postId}/token`] = newToken;
        });
        
        // Применяем все обновления одной транзакцией
        await update(ref(db), updates);
      }
      
      // Обновляем локальное состояние
      setUserData(prev => ({
        ...prev,
        token: newToken
      }));
      
      setShowConfirmRevoke(false);
    } catch (err) {
      console.error('Error revoking token:', err);
      // Можно добавить уведомление об ошибке
    }
  };

  const maskToken = (token) => {
    if (!token) return '';
    if (showFullToken) return token;
    
    // Показываем первые 6 и последние 4 символа
    const firstPart = token.slice(0, 6);
    const lastPart = token.slice(-4);
    const middlePart = '*'.repeat(Math.max(0, token.length - 10));
    
    return `${firstPart}${middlePart}${lastPart}`;
  };

  const handleCopyToken = async () => {
    try {
      // Всегда копируем полный токен, независимо от его отображения
      await navigator.clipboard.writeText(userData.token);
      setIsTokenCopied(true);
      setTimeout(() => setIsTokenCopied(false), 2000);
    } catch (err) {
      console.error('Ошибка при копировании:', err);
    }
  };

  if (loading) {
    return (
      <PageContainer>
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      </PageContainer>
    );
  }

  if (error) {
    return (
      <PageContainer>
        <div className="bg-red-50 text-red-600 p-4 rounded-lg">
          Error loading account data: {error}
        </div>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Информация о подписке */}
        <div className={`bg-white/80 backdrop-blur-sm rounded-xl shadow-lg border ${
          userData.plan === 'Pro' ? 'border-yellow-400' : 'border-gray-100'
        } p-6`}>
          <div className="flex items-center gap-2 mb-6">
            <CreditCardIcon className="w-6 h-6 text-blue-500" />
            <h2 className="text-xl font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
              {t('account.sections.plan.title')}
            </h2>
          </div>
          
          <div className="space-y-6">
            {/* Текущий план */}
            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
              <div className="space-y-1">
                <div className="font-medium text-gray-900">
                  {t('account.sections.plan.currentPlan')}
                </div>
                <div className="text-sm text-gray-500">
                  {t('account.sections.plan.description')}
                </div>
              </div>
              <span className={`inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm font-medium ${
                userData.plan === 'Pro' 
                  ? 'bg-yellow-100 text-yellow-700 border border-yellow-300' 
                  : 'bg-gray-100 text-gray-700'
              }`}>
                {userData.plan === 'Pro' && <CheckBadgeIcon className="w-4 h-4" />}
                {userData.plan}
              </span>
            </div>

            {/* Использование хостов */}
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <ServerStackIcon className="w-5 h-5 text-gray-400" />
                  <span className="text-sm font-medium text-gray-700">
                    {t('account.sections.plan.hostsUsed')}
                  </span>
                </div>
                <span className="text-sm text-gray-600">
                  {userData.hostsUsed} из {userData.hostsLimit}
                </span>
              </div>
              <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                <div 
                  className={`h-full rounded-full transition-all ${
                    (userData.hostsUsed / userData.hostsLimit) > 0.8 
                      ? 'bg-red-500' 
                      : userData.plan === 'Pro' ? 'bg-yellow-500' : 'bg-blue-500'
                  }`}
                  style={{ width: `${(userData.hostsUsed / userData.hostsLimit) * 100}%` }}
                />
              </div>
            </div>

            {userData.plan === 'Free' && (
              <div className="flex justify-end">
                <Link
                  to="/pricing"
                  className="inline-flex items-center gap-2 px-6 py-2.5 rounded-lg text-sm font-medium bg-blue-500 text-white hover:bg-blue-600 transition-colors"
                >
                  <CheckBadgeIcon className="w-5 h-5" />
                  {t('account.sections.plan.upgrade')}
                </Link>
              </div>
            )}
          </div>
        </div>

        {/* API Токен */}
        <div className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg border border-gray-100 p-6">
          <div className="flex flex-col h-full">
            <div className="flex items-center gap-2 mb-6">
              <KeyIcon className="w-6 h-6 text-blue-500" />
              <h2 className="text-xl font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                {t('account.sections.api.title')}
              </h2>
            </div>

            <div className="flex flex-col flex-1 justify-between">
              {/* Основная информация о токене */}
              <div className="space-y-6">
                <div>
                  <div className="flex items-center gap-2 mb-2">
                    <input
                      type="text"
                      value={maskToken(userData.token)}
                      readOnly
                      className="flex-1 bg-gray-50 border rounded-lg px-3 py-2 font-mono text-sm"
                    />
                    <button
                      onClick={() => setShowFullToken(!showFullToken)}
                      className="p-2 text-gray-400 hover:text-blue-500 transition-colors"
                      title={showFullToken ? t('tooltips.account.hideToken') : t('tooltips.account.showToken')}
                    >
                      {showFullToken ? (
                        <EyeSlashIcon className="w-5 h-5" />
                      ) : (
                        <EyeIcon className="w-5 h-5" />
                      )}
                    </button>
                    <button
                      onClick={handleCopyToken}
                      className="p-2 text-gray-400 hover:text-blue-500 transition-colors"
                      title={isTokenCopied ? t('tooltips.account.copied') : t('tooltips.account.copyToken')}
                    >
                      {isTokenCopied ? (
                        <CheckBadgeIcon className="w-5 h-5 text-green-500" />
                      ) : (
                        <ClipboardIcon className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                  <p className="text-sm text-gray-500">
                    {t('account.sections.api.warning')}
                  </p>
                </div>
              </div>

              {/* Опасная зона */}
              {showConfirmRevoke ? (
                <div className="space-y-4">
                  <div className="flex items-start gap-2 text-red-600">
                    <ExclamationTriangleIcon className="w-5 h-5 flex-shrink-0 mt-0.5" />
                    <p className="text-sm">
                      {t('tooltips.account.revokeTokenConfirm')}
                    </p>
                  </div>
                  <div className="flex gap-3">
                    <button
                      onClick={handleRevokeToken}
                      className="flex-1 bg-red-500 text-white px-3 py-2 rounded-lg text-sm font-medium hover:bg-red-600"
                    >
                      {t('tooltips.account.confirmRevoke')}
                    </button>
                    <button
                      onClick={() => setShowConfirmRevoke(false)}
                      className="flex-1 text-gray-600 hover:text-gray-900 text-sm font-medium"
                      title={t('tooltips.account.cancel')}
                    >
                      {t('tooltips.account.cancel')}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex justify-end">
                  <button
                    onClick={() => setShowConfirmRevoke(true)}
                    className="inline-flex items-center gap-2 px-6 py-2.5 rounded-lg text-sm font-medium bg-red-50 text-red-600 hover:bg-red-100 transition-colors"
                  >
                    <ExclamationTriangleIcon className="w-5 h-5" />
                    {t('account.sections.api.revokeButton')}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}; 