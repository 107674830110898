import localforage from 'localforage';

// Инициализация хранилища
localforage.config({
  name: 'dynip-space',
  storeName: 'cache'
});

const CACHE_EXPIRATION = 5 * 60 * 1000; // 5 минут

export const cacheManager = {
  async isValidCache(timestamp) {
    return Date.now() - timestamp < CACHE_EXPIRATION;
  },

  async setCachedData(key, data) {
    try {
      const cacheEntry = {
        data,
        timestamp: Date.now(),
        isValid: true
      };
      await localforage.setItem(key, cacheEntry);
      return true;
    } catch (error) {
      console.error('Cache write error:', error);
      return false;
    }
  },

  async getCachedData(key) {
    try {
      const cached = await localforage.getItem(key);
      if (!cached) return null;

      if (!await this.isValidCache(cached.timestamp)) {
        await localforage.removeItem(key);
        return null;
      }

      return cached.data;
    } catch (error) {
      console.error('Cache read error:', error);
      return null;
    }
  },

  async clearCache(key) {
    try {
      if (key) {
        await localforage.removeItem(key);
      } else {
        await localforage.clear();
      }
      return true;
    } catch (error) {
      console.error('Cache clear error:', error);
      return false;
    }
  },

  // Добавляем метод для проверки поддержки кэширования
  async isSupported() {
    try {
      const testKey = '_test_cache_';
      await this.setCachedData(testKey, { test: true });
      await this.clearCache(testKey);
      return true;
    } catch (error) {
      console.error('Cache is not supported:', error);
      return false;
    }
  }
}; 