import { DocumentTextIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { Header } from './Header';
import { Footer } from './Footer';
import { PageContainer } from './PageContainer';
import { useLocale } from '../contexts/LocaleContext';

export const TermsPage = () => {
  const { t } = useLocale();

  return (
    <div>
      <PageContainer>
        <main className="container mx-auto px-4 py-8">
          <div className="max-w-4xl mx-auto space-y-8">
            <section className="bg-white rounded-lg shadow-md p-6">
              <div className="prose prose-blue max-w-none">
                <h2 className="flex items-center gap-2 text-xl font-semibold mb-6">
                  <DocumentTextIcon className="w-6 h-6 text-blue-500" />
                  {t('terms.hero.title')}
                </h2>

                <p className="text-gray-600 mb-8">
                  {t('terms.hero.lastUpdate')}
                </p>

                <div className="space-y-8">
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-3">
                      {t('terms.sections.general.title')}
                    </h3>
                    <p className="text-gray-600">
                      {t('terms.sections.general.description')}
                    </p>
                  </div>

                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-3">
                      {t('terms.sections.service.title')}
                    </h3>
                    <p className="text-gray-600">
                      {t('terms.sections.service.description')}
                    </p>
                  </div>

                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-3">
                      {t('terms.sections.usage.title')}
                    </h3>
                    <ul className="list-disc list-inside text-gray-600 space-y-2">
                      {t('terms.sections.usage.items', { returnObjects: true }).map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-3">
                      {t('terms.sections.plans.title')}
                    </h3>
                    <div className="space-y-3">
                      <p className="text-gray-600">
                        {t('terms.sections.plans.description')}
                      </p>
                      <ul className="space-y-2">
                        <li className="flex items-start gap-2">
                          <CheckCircleIcon className="w-5 h-5 text-green-500 mt-0.5" />
                          <div>
                            <span className="font-medium text-gray-900">
                              {t('terms.sections.plans.items.free.title')}:
                            </span>
                            <span className="text-gray-600"> {t('terms.sections.plans.items.free.features')}</span>
                          </div>
                        </li>
                        <li className="flex items-start gap-2">
                          <CheckCircleIcon className="w-5 h-5 text-green-500 mt-0.5" />
                          <div>
                            <span className="font-medium text-gray-900">
                              {t('terms.sections.plans.items.pro.title')}:
                            </span>
                            <span className="text-gray-600"> {t('terms.sections.plans.items.pro.features')}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-3">
                      {t('terms.sections.changes.title')}
                    </h3>
                    <p className="text-gray-600">
                      {t('terms.sections.changes.description')}
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </PageContainer>
    </div>
  );
}; 