import { 
  ComputerDesktopIcon, 
  UserCircleIcon, 
  Cog8ToothIcon,
  EnvelopeIcon,
  QuestionMarkCircleIcon,
  PlusIcon,
  Bars3Icon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { Link, useLocation } from 'react-router-dom';
import { LanguageSwitcher } from './LanguageSwitcher';
import { useLocale } from '../contexts/LocaleContext';
import { HostsList } from './HostsList';
import { useAuth } from '../contexts/AuthContext';
import { ADMIN_UID } from '../constants/auth';
import { useState, useEffect } from 'react';
import debug from '../utils/debug';
import { Toaster } from 'react-hot-toast';

export const Layout = ({ children, onLogout, onAddHost }) => {
  const { t } = useLocale();
  const location = useLocation();
  const { user } = useAuth();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  
  const isAdmin = user?.uid === ADMIN_UID;

  const isActivePath = (path) => {
    return location.pathname === path;
  };

  // Функция для определения заголовка страницы
  const getPageTitle = () => {
    switch (location.pathname) {
      case '/hosts':
        return 'pages.titles.hosts';
      case '/account':
        return 'pages.titles.account';
      case '/help':
        return 'pages.titles.help';
      case '/admin':
        return 'pages.titles.admin';
      case '/features':
        return 'pages.titles.features';
      case '/pricing':
        return 'pages.titles.pricing';
      case '/blog':
        return 'pages.titles.blog';
      case '/about':
        return 'pages.titles.about';
      case '/terms':
        return 'pages.titles.terms';
      case '/privacy':
        return 'pages.titles.privacy';
      default:
        return '';
    }
  };

  // Функция для определения дополнительных действий для страницы
  const renderPageAction = () => {
    if (location.pathname === '/hosts') {
      return (
        <button
          onClick={onAddHost}
          className="inline-flex items-center gap-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors
            px-2 py-2 sm:px-4 sm:py-2"
        >
          <PlusIcon className="w-5 h-5" />
          <span className="hidden sm:inline">
            {t('pages.actions.addHost')}
          </span>
        </button>
      );
    }
    return null;
  };

  useEffect(() => {
    debug('Auth state changed:', user);
  }, [user]);

  const handleError = (error) => {
    debug('Layout error:', error);
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* Верхняя панель - всегда видима */}
      <header className="bg-white shadow-sm z-20">
        <div className="h-16 px-4 flex items-center justify-between">
          <div className="flex items-center gap-3">
            <button 
              className="sm:hidden"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              <Bars3Icon className="w-6 h-6 text-gray-500" />
            </button>
            <div className="flex items-center gap-3">
              <img 
                src="/unicorn-logo.svg" 
                alt={t('common.brandName')} 
                className="h-8 w-8" 
              />
              <span className="font-bold text-gray-900 hidden sm:block">
                {t('common.brandName')}
              </span>
            </div>
          </div>
          
          <div className="flex items-center gap-2 sm:gap-4">
            {renderPageAction()}
            <LanguageSwitcher />
            <button
              onClick={onLogout}
              className="text-gray-600 hover:text-gray-900 transition-colors px-2 sm:px-4 py-2 text-sm font-medium"
            >
              {t('common.logout')}
            </button>
          </div>
        </div>
      </header>

      <div className="flex-1 flex relative">
        {/* Затемнение фона при открытом меню */}
        {isMobileMenuOpen && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-30 sm:hidden"
            onClick={() => setIsMobileMenuOpen(false)}
          />
        )}

        {/* Боковая навигация */}
        <nav className={`
          fixed sm:static inset-y-0 left-0 w-64 bg-white shadow-lg z-40
          transform ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}
          sm:translate-x-0 transition-transform duration-300
          overflow-y-auto
        `}>
          <div className="py-4">
            <Link 
              to="/hosts" 
              className={`flex items-center gap-2 px-4 py-2 ${
                isActivePath('/hosts')
                  ? 'bg-blue-50 text-blue-600 border-r-4 border-blue-600'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              <ComputerDesktopIcon className="w-5 h-5" />
              {t('nav.hosts')}
            </Link>
            <Link 
              to="/account" 
              className={`flex items-center gap-2 px-4 py-2 ${
                isActivePath('/account')
                  ? 'bg-blue-50 text-blue-600 border-r-4 border-blue-600'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              <UserCircleIcon className="w-5 h-5" />
              {t('nav.account')}
            </Link>
            {isAdmin && (
              <Link 
                to="/admin" 
                className={`flex items-center gap-2 px-4 py-2 ${
                  isActivePath('/admin')
                    ? 'bg-blue-50 text-blue-600 border-r-4 border-blue-600'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <Cog8ToothIcon className="w-5 h-5" />
                {t('nav.admin')}
              </Link>
            )}
          </div>
        </nav>

        {/* Основной контент */}
        <main className="flex-grow p-4 flex flex-col">
          <div className="flex flex-col h-full">
            <div className="px-4 sm:px-6 lg:px-8">
              <h1 className="text-xl sm:text-2xl font-bold text-gray-900">
                {t(getPageTitle())}
              </h1>
            </div>
            <div className="mt-6 flex-grow">
              {children}
            </div>
          </div>
        </main>
      </div>
      <Toaster 
        position="top-right"
        toastOptions={{
          duration: 3000,
        }}
      />
    </div>
  );
}; 