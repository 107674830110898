import { useState, forwardRef, useImperativeHandle, useEffect, useMemo } from 'react';
import { HostCard } from './HostCard';
import { AddHostModal } from './AddHostModal';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { ref as databaseRef, get, child, onValue, off } from 'firebase/database';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { useLocale } from '../contexts/LocaleContext';
import { useTranslation } from 'react-i18next';
import debug from '../utils/debug';
import { cacheManager } from '../utils/cache';
import { toast } from 'react-hot-toast';

const EmptyState = ({ onAddClick }) => {
  const { t } = useLocale();
  
  return (
    <div className="flex flex-col items-center justify-center h-full py-8">
      <div className="flex justify-center">
        <div className="relative">
          <div className="absolute -inset-4">
            <div className="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-blue-400 to-purple-400" />
          </div>
          <PlusCircleIcon className="relative w-24 h-24 text-blue-500" />
        </div>
      </div>
      <h3 className="mt-6 text-xl font-semibold text-gray-900">
        {t('hosts.empty.title')}
      </h3>
      <p className="mt-2 text-sm text-gray-600 max-w-sm mx-auto text-center">
        {t('hosts.empty.description')}
      </p>
      <div className="mt-6">
        <button
          onClick={onAddClick}
          className="inline-flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <PlusCircleIcon className="w-5 h-5" />
          {t('hosts.empty.addButton')}
        </button>
      </div>
    </div>
  );
};

export const HostsList = forwardRef(({ onDeleteHost, onUpdateHost, onAddHost }, ref) => {
  const { t } = useLocale();
  const { i18n } = useTranslation();
  const { user } = useAuth();
  const [showAddModal, setShowAddModal] = useState(false);
  const [hosts, setHosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cachedHosts, setCachedHosts] = useState({});

  const handleCacheError = (error) => {
    console.error('Cache error:', error);
    
    let errorMessage = t('errors.cacheError');
    
    if (error.name === 'QuotaExceededError') {
      errorMessage = t('errors.cache.write');
    } else if (error.name === 'DataCloneError') {
      errorMessage = t('errors.cache.invalid');
    }
    
    toast.error(errorMessage);
  };

  const handleDeleteHost = async (hostId) => {
    try {
      await onDeleteHost(hostId);
      
      const updatedHosts = hosts.filter(host => host.id !== hostId);
      setHosts(updatedHosts);
      
      try {
        setCachedHosts(prevCache => {
          const newCache = { ...prevCache };
          delete newCache[hostId];
          return newCache;
        });
        
        cacheManager.setCachedData(`hosts_${user.uid}`, updatedHosts);
      } catch (cacheError) {
        console.error('Cache update error:', cacheError);
        toast.error(t('errors.cache.write'));
      }
      
      // toast.success(t('notifications.hosts.deleted'));
      toast.success("Host successfully deleted");
    } catch (error) {
      // console.error('Error in handleDeleteHost:', error);
      // toast.error(t('notifications.hosts.deleteError'));
      toast.error("Error deleting host");
    }
  };

  useEffect(() => {
    debug('Fetching hosts...');
    
    if (user?.uid) {
      const userPostsRef = databaseRef(db, `user-posts/${user.uid}`);
      const postsRef = databaseRef(db, 'posts');
      const unsubscribers = [];

      // Подписываемся на изменения в списке постов пользователя
      const unsubscribeUserPosts = onValue(userPostsRef, (snapshot) => {
        const userPosts = snapshot.val() || {};
        
        // Отписываемся от старых подписок
        unsubscribers.forEach(unsub => unsub());
        unsubscribers.length = 0;

        // Получаем текущие ID постов
        const currentPostIds = Object.keys(userPosts);
        
        // Если постов нет, очищаем список хостов и завершаем загрузку
        if (currentPostIds.length === 0) {
          setHosts([]);
          setLoading(false);
          return;
        }
        
        // Удаляем хосты, которых больше нет в userPosts
        setHosts(prevHosts => {
          const updatedHosts = prevHosts.filter(host => currentPostIds.includes(host.id));
          
          // Обновляем кэш при изменении списка хостов
          try {
            cacheManager.setCachedData(`hosts_${user.uid}`, updatedHosts);
            setCachedHosts(prevCache => {
              const newCache = { ...prevCache };
              updatedHosts.forEach(host => {
                newCache[host.id] = host;
              });
              return newCache;
            });
          } catch (cacheError) {
            console.error('Cache update error:', cacheError);
            toast.error(t('errors.cache.write'));
          }
          
          return updatedHosts;
        });

        let loadedPosts = 0;
        // Создаем новые подписки для каждого поста
        currentPostIds.forEach(postId => {
          const postRef = databaseRef(db, `posts/${postId}`);
          const unsubscribe = onValue(postRef, (postSnapshot) => {
            const postVal = postSnapshot.val();
            if (postVal) {
              setHosts(prevHosts => {
                const newHosts = prevHosts.filter(h => h.id !== postId);
                const updatedHosts = [...newHosts, {
                  id: postId,
                  name: postVal.title || '',
                  ip: postVal.ip || '',
                  lastUpdate: userPosts[postId].updated || null
                }].sort((a, b) => a.name.localeCompare(b.name));
                
                // Обновляем кэш при изменении данных хоста
                try {
                  cacheManager.setCachedData(`hosts_${user.uid}`, updatedHosts);
                  setCachedHosts(prevCache => {
                    // Создаем новый объект кэша из обновленного списка хостов
                    return updatedHosts.reduce((acc, host) => {
                      acc[host.id] = host;
                      return acc;
                    }, {});
                  });
                } catch (cacheError) {
                  console.error('Cache update error:', cacheError);
                  toast.error(t('errors.cache.write'));
                }
                
                loadedPosts++;
                // Если загрузили все посты, завершаем загрузку
                if (loadedPosts === currentPostIds.length) {
                  setLoading(false);
                }
                
                return updatedHosts;
              });
            } else {
              loadedPosts++;
              // Если загрузили все посты, завершаем загрузку
              if (loadedPosts === currentPostIds.length) {
                setLoading(false);
              }
            }
          });
          unsubscribers.push(unsubscribe);
        });

      }, (error) => {
        debug('Error fetching hosts:', error);
        setError(error.message);
        setLoading(false);
        toast.error(t('errors.loadingHosts'));
      });

      return () => {
        unsubscribeUserPosts();
        unsubscribers.forEach(unsub => unsub());
      };
    }
  }, [user?.uid]);

  const sortedHosts = useMemo(() => {
    return Object.values(cachedHosts)
      .sort((a, b) => b.lastSeen - a.lastSeen);
  }, [cachedHosts]);

  const handleAdd = async (hostData) => {
    try {
      await onAddHost(hostData);
      // toast.success(t('notifications.hosts.added'));
      setShowAddModal(false);
    } catch (error) {
      console.error('Error adding host:', error);
      toast.error(t('hosts.errors.addHost.default'));
    }
  };

  const handleUpdateHost = async (id, newIp) => {
    try {
      // Находим хост, который обновляется
      const hostToUpdate = hosts.find(host => host.id === id);
      if (!hostToUpdate) {
        throw new Error('Host not found');
      }

      if (i18n.isInitialized) {
        toast.success(t('notifications.ipUpdated'));
      } else {
        toast.success('IP address successfully updated');
      }
    } catch (error) {
      console.error('Error updating host:', error);
      toast.error(t('notifications.hosts.updateError'));
    }
  };

  useImperativeHandle(ref, () => ({
    openAddModal: () => {
      setShowAddModal(true);
    }
  }));

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg">
          Error loading hosts: {error}
        </div>
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="container mx-auto px-4 h-full">
        <div className={`h-full ${hosts.length === 0 ? 'flex items-center justify-center' : 'py-8'}`}>
          {hosts.length === 0 ? (
            <EmptyState onAddClick={() => setShowAddModal(true)} />
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
              {sortedHosts.map((host) => (
                <div 
                  key={host.id}
                  className="transform hover:-translate-y-1.5 transition-all duration-300"
                >
                  <HostCard
                    host={host}
                    onDeleteHost={handleDeleteHost}
                    onUpdate={onUpdateHost}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {showAddModal && (
        <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-2xl shadow-2xl w-full max-w-lg mx-auto">
            <AddHostModal
              onClose={() => setShowAddModal(false)}
              onAdd={handleAdd}
            />
          </div>
        </div>
      )}
    </div>
  );
}); 