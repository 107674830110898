import { 
  CloudArrowUpIcon,
  ShieldCheckIcon,
  BoltIcon,
  DevicePhoneMobileIcon,
  ArrowPathIcon,
  CommandLineIcon,
  ChartBarIcon,
  ServerIcon,
  QuestionMarkCircleIcon,
  EnvelopeIcon
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Footer } from './Footer';
import { Header } from './Header';
import { useLocale } from '../contexts/LocaleContext';

const FeatureCard = ({ title, description, icon: Icon }) => (
  <div className="group relative bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-all duration-300 overflow-hidden">
    <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
    <div className="relative flex items-baseline gap-4">
      <div className="flex-shrink-0">
        <Icon className="w-6 h-6 text-blue-500 group-hover:scale-110 transition-transform duration-300" />
      </div>
      <div>
        <h3 className="text-lg font-semibold text-gray-900 mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  </div>
);

const StatsCard = ({ value, label }) => (
  <div className="text-center">
    <div className="text-4xl font-bold text-white mb-2">{value}</div>
    <div className="text-sm text-blue-100">{label}</div>
  </div>
);

export const LandingPage = () => {
  const { t } = useLocale();

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-1">
        {/* Hero секция с единорогом */}
        <section className="relative overflow-hidden pt-32 pb-16 sm:pt-40 sm:pb-24">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-50 via-purple-50 to-transparent" />
          </div>
          <div className="container mx-auto px-4 relative">
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <div>
                <h1 className="text-4xl sm:text-5xl font-bold text-gray-900 mb-6 leading-tight">
                  {t('landing.hero.title')}{' '}
                  <span className="bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                    {t('landing.hero.titleHighlight')}
                  </span>
                </h1>
                <p className="text-xl text-gray-600 mb-8">
                  {t('landing.hero.description')}
                </p>
                <div className="flex flex-wrap gap-4">
                  <Link 
                    to="/login"
                    className="inline-flex items-center px-6 py-3 rounded-lg bg-gradient-to-r from-blue-600 to-purple-600 text-white font-medium hover:from-blue-700 hover:to-purple-700 transition-colors shadow-lg hover:shadow-xl"
                  >
                    {t('common.startFree')}
                    <BoltIcon className="w-5 h-5 ml-2" />
                  </Link>
                  <Link 
                    to="/features"
                    className="inline-flex items-center px-6 py-3 rounded-lg bg-white text-gray-900 font-medium hover:bg-gray-50 transition-colors shadow-md hover:shadow-lg"
                  >
                    {t('common.learnMore')}
                    <ArrowPathIcon className="w-5 h-5 ml-2" />
                  </Link>
                </div>
              </div>
              <div className="relative hidden md:block">
                <div className="relative">
                  <img 
                    src="/unicorn.svg" 
                    alt="DynIP Space Unicorn" 
                    className="w-full max-w-lg mx-auto animate-float hover:animate-wiggle transition-all duration-300"
                  />
                  {/* Декоративные звезды */}
                  <div className="absolute top-1/4 right-1/4 w-3 h-3 bg-yellow-300 rounded-full animate-pulse" />
                  <div className="absolute bottom-1/3 left-1/3 w-2 h-2 bg-yellow-300 rounded-full animate-pulse delay-75" />
                  <div className="absolute top-1/2 right-1/3 w-2 h-2 bg-yellow-300 rounded-full animate-pulse delay-150" />
                  <div className="absolute bottom-1/4 right-1/2 w-3 h-3 bg-yellow-300 rounded-full animate-pulse delay-300" />
                </div>

                {/* Декоративные элементы */}
                <div className="absolute top-1/4 right-1/4 w-20 h-20 bg-blue-400 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob" />
                <div className="absolute bottom-1/4 left-1/4 w-20 h-20 bg-purple-400 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000" />
                <div className="absolute top-1/2 left-1/3 w-20 h-20 bg-pink-400 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000" />

                {/* Декоративные облака */}
                <div className="absolute top-0 left-0 w-32 h-12 bg-white/30 rounded-full filter blur-xl animate-blob" />
                <div className="absolute bottom-1/4 right-1/4 w-24 h-8 bg-white/30 rounded-full filter blur-xl animate-blob animation-delay-2000" />
                <div className="absolute top-1/3 right-0 w-28 h-10 bg-white/30 rounded-full filter blur-xl animate-blob animation-delay-4000" />
              </div>
            </div>
          </div>
        </section>

        {/* Статистика */}
        <section className="py-16 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-white">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              <StatsCard value="50,000+" label={t('landing.stats.activeUsers')} />
              <StatsCard value="1M+" label={t('landing.stats.updatesDaily')} />
              <StatsCard value="99.9%" label={t('landing.stats.uptime')} />
              <StatsCard value="150+" label={t('landing.stats.countries')} />
            </div>
          </div>
        </section>

        {/* Добавляем новую секцию с волнистым разделителем */}
        <div className="relative">
          <svg className="absolute top-0 w-full h-24 -mt-12" viewBox="0 0 1440 120" preserveAspectRatio="none">
            <path 
              d="M0,0 C480,100 960,100 1440,0 L1440,120 L0,120 Z" 
              className="fill-blue-500"
            />
          </svg>
        </div>

        {/* Новая секция с градиентными карточками */}
        <section className="py-24 bg-gradient-to-b from-blue-500 to-purple-600">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center text-white mb-12">
              {t('landing.features.title')}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-8 text-white hover:transform hover:-translate-y-1 transition-all">
                <CommandLineIcon className="w-12 h-12 mb-6" />
                <h3 className="text-xl font-semibold mb-4">{t('landing.features.api.title')}</h3>
                <p className="text-blue-100">{t('landing.features.api.description')}</p>
              </div>
              <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-8 text-white hover:transform hover:-translate-y-1 transition-all">
                <ServerIcon className="w-12 h-12 mb-6" />
                <h3 className="text-xl font-semibold mb-4">{t('landing.features.automation.title')}</h3>
                <p className="text-blue-100">{t('landing.features.automation.description')}</p>
              </div>
              <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-8 text-white hover:transform hover:-translate-y-1 transition-all">
                <ChartBarIcon className="w-12 h-12 mb-6" />
                <h3 className="text-xl font-semibold mb-4">{t('landing.features.monitoring.title')}</h3>
                <p className="text-blue-100">{t('landing.features.monitoring.description')}</p>
              </div>
            </div>
          </div>
        </section>

        {/* CTA секция */}
        <section className="relative py-24 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-purple-500 via-pink-500 to-red-500">
            <div className="absolute inset-0 bg-grid-white/10" />
          </div>
          <div className="relative container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold text-white mb-8">
              {t('landing.cta.title')}
            </h2>
            <p className="text-xl text-white/90 mb-12 max-w-2xl mx-auto">
              {t('landing.cta.description')}
            </p>
            <Link 
              to="/login"
              className="inline-flex items-center px-8 py-4 rounded-full bg-white text-purple-600 font-medium hover:bg-purple-50 transition-colors shadow-lg hover:shadow-xl"
            >
              {t('landing.cta.button')}
              <BoltIcon className="w-5 h-5 ml-2" />
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
}; 