import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useLocale } from '../contexts/LocaleContext';

export const LoginPage = () => {
  const { signIn, user } = useAuth();
  const { t } = useLocale();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user) {
      navigate('/hosts');
    }
  }, [user, navigate]);

  const handleSignIn = async () => {
    try {
      setError(null);
      const result = await signIn();  // Получаем результат входа через Google
      
      // Отправляем данные на сервер
      console.log('Sending login request with data:', {
        uid: result.user.uid,
        email: result.user.email,
        displayName: result.user.displayName
      });
      
      const response = await fetch('/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: result.user.uid,
          email: result.user.email,
          displayName: result.user.displayName
        })
      });
      
      const data = await response.json();
      console.log('Login response:', data);
      
      if (!response.ok) {
        throw new Error(data.message || 'Server error');
      }
      
      navigate('/hosts');
    } catch (error) {
      console.error('Login error:', error);
      if (error.message.includes('blocked')) {
        setError(t('auth.errors.accountBlocked'));
      } else {
        setError(t('auth.errors.default'));
      }
    }
  };

  const handleLogin = async () => {
    try {
      const user = // ... получение данных пользователя после аутентификации
      console.log('Sending login request with data:', {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName
      });
      
      const response = await fetch('/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName
        })
      });
      
      const data = await response.json();
      console.log('Login response:', data);
      
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 relative">
      <button
        onClick={handleGoBack}
        className="absolute top-4 left-4 flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors px-3 py-2 rounded-lg hover:bg-gray-100"
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="h-5 w-5" 
          viewBox="0 0 20 20" 
          fill="currentColor"
        >
          <path 
            fillRule="evenodd" 
            d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" 
            clipRule="evenodd" 
          />
        </svg>
        <span>{t('common.back')}</span>
      </button>

      <div className="max-w-md w-full space-y-8 p-8">
        <div className="text-center">
          <img 
            src="/unicorn-logo.svg" 
            alt={t('common.brandName')} 
            className="mx-auto h-12 w-12"
          />
          <h2 className="mt-6 text-3xl font-bold text-gray-900">
            {t('auth.welcome')}
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            {t('auth.description')}
          </p>
        </div>

        {error && (
          <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
            {error}
          </div>
        )}

        <div className="mt-8 space-y-6">
          <button
            onClick={handleSignIn}
            className="w-full flex items-center justify-center gap-3 px-4 py-3 border border-gray-300 rounded-lg shadow-sm bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <img 
              src="/google-logo.svg" 
              alt="Google" 
              className="w-5 h-5"
            />
            <span>{t('auth.continueWithGoogle')}</span>
          </button>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-gray-50 text-gray-500">
                {t('auth.or')}
              </span>
            </div>
          </div>

          <div className="text-center">
            <p className="text-sm text-gray-600">
              {t('auth.noAccount')}{' '}
              <a href="/login" className="font-medium text-blue-600 hover:text-blue-500">
                {t('auth.createAccount')}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}; 