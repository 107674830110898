import { 
  CheckCircleIcon,
  XCircleIcon,
  BoltIcon,
  ServerIcon,
  ChartBarIcon,
  ShieldCheckIcon,
  ClockIcon,
  CommandLineIcon
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { PageContainer } from './PageContainer';
import { Footer } from './Footer';
import { Header } from './Header';
import { useLocale } from '../contexts/LocaleContext';

const Feature = ({ included, children, isLight }) => (
  <li className="flex items-center gap-2">
    {included ? (
      <CheckCircleIcon className={`w-5 h-5 ${isLight ? 'text-white' : 'text-green-500'} flex-shrink-0`} />
    ) : (
      <XCircleIcon className="w-5 h-5 text-gray-300 flex-shrink-0" />
    )}
    <span className={
      included 
        ? isLight ? "text-white" : "text-gray-700"
        : "text-gray-400"
    }>
      {children}
    </span>
  </li>
);

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="bg-white/80 backdrop-blur-sm rounded-xl p-6 shadow-lg border border-gray-100">
    <div className="flex items-center gap-4 mb-4">
      <div className="p-3 bg-blue-50 rounded-lg">
        <Icon className="w-6 h-6 text-blue-500" />
      </div>
      <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
    </div>
    <p className="text-gray-600">{description}</p>
  </div>
);

export const PricingPage = () => {
  const { t } = useLocale();

  // Получаем массивы с параметром returnObjects
  const freeFeatures = t('pricing.plans.free.features', { returnObjects: true });
  const proFeatures = t('pricing.plans.pro.features', { returnObjects: true });
  const faqItems = t('pricing.faq.items', { returnObjects: true });

  return (
    <div>
      <PageContainer>
        <main className="container mx-auto px-4 py-8">
          {/* Заголовок */}
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent mb-4">
              {t('pricing.hero.title')}
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              {t('pricing.hero.description')}
            </p>
          </div>

          {/* Тарифные планы */}
          <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto mb-16">
            {/* Free план */}
            <div className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg border border-gray-100 p-8">
              <div className="mb-8">
                <h2 className="text-2xl font-bold text-gray-900 mb-4">{t('pricing.plans.free.name')}</h2>
                <div className="flex items-baseline gap-2 mb-6">
                  <span className="text-4xl font-bold text-gray-900">{t('pricing.plans.free.price')}</span>
                  <span className="text-gray-500">{t('pricing.plans.free.period')}</span>
                </div>
                <p className="text-gray-600">
                  {t('pricing.plans.free.description')}
                </p>
              </div>

              <ul className="space-y-4 mb-8">
                {Array.isArray(freeFeatures) && freeFeatures.map((feature, index) => (
                  <Feature key={index} included={index < 4}>{feature}</Feature>
                ))}
              </ul>

              <Link 
                to="/login"
                className="block text-center py-3 px-6 rounded-lg bg-gray-900 text-white font-medium hover:bg-gray-800 transition-colors"
              >
                {t('pricing.plans.free.button')}
              </Link>
            </div>

            {/* Pro план */}
            <div className="bg-gradient-to-b from-blue-600 to-purple-600 rounded-xl shadow-lg p-8 text-white relative overflow-hidden">
              <div className="absolute top-4 right-4">
                <span className="inline-flex items-center px-3 py-1 rounded-full bg-white/20 text-sm font-medium">
                  {t('pricing.plans.pro.badge')}
                </span>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-bold mb-4">{t('pricing.plans.pro.name')}</h2>
                <div className="flex items-baseline gap-2 mb-6">
                  <span className="text-4xl font-bold">{t('pricing.plans.pro.price')}</span>
                  <span className="opacity-80">{t('pricing.plans.pro.period')}</span>
                </div>
                <p className="opacity-80">
                  {t('pricing.plans.pro.description')}
                </p>
              </div>

              <ul className="space-y-4 mb-8">
                {Array.isArray(proFeatures) && proFeatures.map((feature, index) => (
                  <Feature key={index} included isLight>{feature}</Feature>
                ))}
              </ul>

              <Link 
                to="/login?plan=pro"
                className="block text-center py-3 px-6 rounded-lg bg-white text-blue-600 font-medium hover:bg-blue-50 transition-colors"
              >
                {t('pricing.plans.pro.button')}
              </Link>
            </div>
          </div>

          {/* Преимущества */}
          <div className="mb-16">
            <h2 className="text-2xl font-bold text-center text-gray-900 mb-8">
              {t('pricing.features.title')}
            </h2>
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
              <FeatureCard
                icon={BoltIcon}
                title={t('pricing.features.cards.updates.title')}
                description={t('pricing.features.cards.updates.description')}
              />
              <FeatureCard
                icon={ShieldCheckIcon}
                title={t('pricing.features.cards.security.title')}
                description={t('pricing.features.cards.security.description')}
              />
              <FeatureCard
                icon={CommandLineIcon}
                title={t('pricing.features.cards.api.title')}
                description={t('pricing.features.cards.api.description')}
              />
              <FeatureCard
                icon={ChartBarIcon}
                title={t('pricing.features.cards.monitoring.title')}
                description={t('pricing.features.cards.monitoring.description')}
              />
            </div>
          </div>

          {/* FAQ */}
          <div className="max-w-5xl mx-auto">
            <h2 className="text-2xl font-bold text-center text-gray-900 mb-12">
              {t('pricing.faq.title')}
            </h2>
            <div className="grid md:grid-cols-2 gap-6">
              {Array.isArray(faqItems) && faqItems.map((item, index) => (
                <div 
                  key={index} 
                  className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg border border-gray-100 p-6 hover:shadow-xl transition-all duration-300"
                >
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">
                    {item.question}
                  </h3>
                  <p className="text-gray-600">
                    {item.answer}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </main>
      </PageContainer>
    </div>
  );
}; 