import { Link } from 'react-router-dom';
import { LanguageSwitcher } from './LanguageSwitcher';
import { useLocale } from '../contexts/LocaleContext';

export const Header = () => {
  const { t } = useLocale();

  return (
    <nav className="absolute top-0 left-0 right-0 z-10">
      <div className="container mx-auto px-4 py-6">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex items-center gap-2">
            <img 
              src="/unicorn-logo.svg" 
              alt={t('common.brandName')} 
              className="h-8 w-8 transform hover:scale-110 transition-transform duration-300" 
            />
            <span className="font-bold text-gray-900 text-xl">{t('common.brandName')}</span>
          </Link>
          <div className="flex items-center gap-4">
            <LanguageSwitcher />
            <Link 
              to="/login"
              className="inline-flex items-center px-4 py-2 rounded-lg bg-blue-600 text-white font-medium hover:bg-blue-700 transition-colors"
              type="button"
            >
              {t('common.login')}
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}; 