export const PageContainer = ({ children, className = "" }) => (
  <main className={`container mx-auto px-4 py-8 ${className}`}>
    <div className="relative">
      {/* Декоративные круги */}
      <div className="absolute top-0 right-0 w-64 h-64 bg-blue-400 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob" />
      <div className="absolute top-0 left-0 w-64 h-64 bg-purple-400 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob animation-delay-2000" />
      <div className="absolute bottom-0 right-1/4 w-64 h-64 bg-pink-400 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob animation-delay-4000" />
      
      {/* Основной контент */}
      <div className="relative">
        {children}
      </div>
    </div>
  </main>
); 