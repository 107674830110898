import { 
  CalendarIcon, 
  UserIcon,
  TagIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline';
import { PageContainer } from './PageContainer';
import { useLocale } from '../contexts/LocaleContext';

export const BlogPage = () => {
  const { t } = useLocale();
  const posts = t('blog.posts', { returnObjects: true });
  
  console.log('Posts from translation:', posts);

  if (!Array.isArray(posts) || posts.length === 0) {
    console.log('No posts found or invalid posts data');
    return (
      <div>
        <PageContainer>
          <main className="container mx-auto px-4 py-8">
            <div className="text-center">
              <p>Нет доступных постов</p>
            </div>
          </main>
        </PageContainer>
      </div>
    );
  }

  return (
    <div>
      <PageContainer>
        <main className="container mx-auto px-4 py-8">
          {/* Hero секция */}
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent mb-4">
              {t('blog.hero.title')}
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              {t('blog.hero.description')}
            </p>
          </div>

          {/* Список постов */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {Array.isArray(posts) && posts.map((post) => (
              <div key={post.id} className="transform hover:-translate-y-1 transition-all duration-300">
                <article className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg border border-gray-100 overflow-hidden h-full flex flex-col">
                  <div className="p-6 flex-grow">
                    <div className="inline-flex items-center px-3 py-1 rounded-full bg-blue-50 text-blue-600 text-sm font-medium mb-4">
                      <span>{post.category}</span>
                    </div>
                    
                    <h2 className="text-xl font-semibold text-gray-900 mb-3 line-clamp-2">
                      <a href={`/blog/${post.slug}`} className="hover:text-blue-600">
                        {post.title}
                      </a>
                    </h2>
                    
                    <p className="text-gray-600 mb-4 line-clamp-3">
                      {post.excerpt}
                    </p>
                    
                    <div className="flex items-center justify-between text-sm text-gray-500">
                      <div className="flex items-center gap-4">
                        <div className="flex items-center gap-1">
                          <UserIcon className="w-4 h-4" />
                          <span>{post.author}</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <CalendarIcon className="w-4 h-4" />
                          <time dateTime={post.date}>
                            {new Date(post.date).toLocaleDateString(t('common.locale'), {
                              day: 'numeric',
                              month: 'long',
                              year: 'numeric'
                            })}
                          </time>
                        </div>
                      </div>
                      <span>{post.readTime}</span>
                    </div>
                  </div>
                  
                  <div className="border-t border-gray-100 px-6 py-3 bg-gray-50">
                    <div className="flex flex-wrap gap-2">
                      {post.tags.map((tag) => (
                        <span 
                          key={tag}
                          className="inline-flex items-center text-xs text-gray-600 bg-gray-100 px-2 py-1 rounded-full"
                        >
                          #{tag}
                        </span>
                      ))}
                    </div>
                  </div>
                </article>
              </div>
            ))}
          </div>
        </main>
      </PageContainer>
    </div>
  );
}; 