import { 
  CloudArrowUpIcon,
  ShieldCheckIcon,
  ClockIcon,
  CogIcon,
  ChartBarIcon,
  CommandLineIcon,
  ServerIcon,
  GlobeAltIcon,
  BoltIcon
} from '@heroicons/react/24/outline';
import { Header } from './Header';
import { Footer } from './Footer';
import { PageContainer } from './PageContainer';
import { Link } from 'react-router-dom';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useLocale } from '../contexts/LocaleContext';

// Массив иконок для карточек в том же порядке, что и в локализации
const featureIcons = [
  CloudArrowUpIcon,
  ShieldCheckIcon,
  ChartBarIcon,
  CogIcon,
  ClockIcon,
  CommandLineIcon,
  GlobeAltIcon,
  ServerIcon
];

const FeatureCard = ({ title, description, icon: Icon }) => (
  <div className="bg-white rounded-lg shadow-md p-6">
    <div className="flex items-baseline gap-4">
      <div className="flex-shrink-0">
        <Icon className="w-5 h-5 text-blue-500" />
      </div>
      <div>
        <h3 className="text-lg font-semibold text-gray-900 mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  </div>
);

export const FeaturesPage = () => {
  const { t } = useLocale();

  // Получаем массив карточек
  const cards = t('features.cards', { returnObjects: true });

  return (
    <div>
      <PageContainer>
        <main className="container mx-auto px-4 py-8">
          <div className="max-w-5xl mx-auto space-y-16">
            {/* Hero секция */}
            <section className="text-center">
              <h1 className="text-3xl font-bold text-gray-900 mb-4">
                {t('features.hero.title')}
              </h1>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                {t('features.hero.description')}
              </p>
            </section>

            {/* Основные возможности */}
            <section>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {Array.isArray(cards) && cards.map((card, index) => (
                  <FeatureCard 
                    key={index}
                    title={card.title}
                    description={card.description}
                    icon={featureIcons[index]}
                  />
                ))}
              </div>
            </section>

            {/* Тарифы */}
            <section className="bg-gradient-to-r from-blue-600 to-purple-600 rounded-2xl p-8 text-white text-center">
              <h2 className="text-2xl font-bold mb-4">
                {t('features.pricing.title')}
              </h2>
              <p className="text-lg opacity-90 mb-8 max-w-2xl mx-auto">
                {t('features.pricing.description')}
              </p>
              <Link 
                to="/pricing"
                className="inline-flex items-center px-6 py-3 rounded-lg bg-white text-blue-600 font-medium hover:bg-blue-50 transition-colors"
              >
                {t('features.pricing.button')}
                <BoltIcon className="w-5 h-5 ml-2" />
              </Link>
            </section>

            {/* CTA секция */}
            <section className="text-center">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">
                {t('features.cta.title')}
              </h2>
              <p className="text-lg text-gray-600 mb-8 max-w-2xl mx-auto">
                {t('features.cta.description')}
              </p>
              <Link 
                to="/login"
                className="inline-flex items-center px-6 py-3 rounded-lg bg-gray-900 text-white font-medium hover:bg-gray-800 transition-colors"
              >
                {t('features.cta.button')}
                <BoltIcon className="w-5 h-5 ml-2" />
              </Link>
            </section>
          </div>
        </main>
      </PageContainer>
    </div>
  );
};