import { ShieldCheckIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { Header } from './Header';
import { Footer } from './Footer';
import { PageContainer } from './PageContainer';
import { useLocale } from '../contexts/LocaleContext';

export const PrivacyPage = () => {
  const { t } = useLocale();

  return (
    <div>
      <PageContainer>
        <main className="container mx-auto px-4 py-8">
          <div className="max-w-4xl mx-auto space-y-8">
            <section className="bg-white rounded-lg shadow-md p-6">
              <div className="prose prose-blue max-w-none">
                <h2 className="flex items-center gap-2 text-xl font-semibold mb-6">
                  <ShieldCheckIcon className="w-6 h-6 text-blue-500" />
                  {t('privacy.hero.title')}
                </h2>

                <p className="text-gray-600 mb-8">
                  {t('privacy.hero.lastUpdate')}
                </p>

                <div className="space-y-8">
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-3">
                      {t('privacy.sections.intro.title')}
                    </h3>
                    <p className="text-gray-600">
                      {t('privacy.sections.intro.description')}
                    </p>
                  </div>

                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-3">
                      {t('privacy.sections.data.title')}
                    </h3>
                    <div className="space-y-3">
                      <p className="text-gray-600">{t('privacy.sections.data.description')}</p>
                      <ul className="list-disc list-inside text-gray-600 space-y-2">
                        {t('privacy.sections.data.items', { returnObjects: true }).map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-3">
                      {t('privacy.sections.usage.title')}
                    </h3>
                    <div className="space-y-3">
                      <p className="text-gray-600">{t('privacy.sections.usage.description')}</p>
                      <ul className="space-y-2">
                        {t('privacy.sections.usage.items', { returnObjects: true }).map((item, index) => (
                          <li key={index} className="flex items-start gap-2">
                            <LockClosedIcon className="w-5 h-5 text-blue-500 mt-0.5" />
                            <span className="text-gray-600">{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-3">
                      {t('privacy.sections.protection.title')}
                    </h3>
                    <p className="text-gray-600">
                      {t('privacy.sections.protection.description')}
                    </p>
                  </div>

                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-3">
                      {t('privacy.sections.cookies.title')}
                    </h3>
                    <p className="text-gray-600">
                      {t('privacy.sections.cookies.description')}
                    </p>
                  </div>

                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-3">
                      {t('privacy.sections.rights.title')}
                    </h3>
                    <p className="text-gray-600">
                      {t('privacy.sections.rights.description')}
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </PageContainer>
    </div>
  );
}; 