import { useLocale } from '../contexts/LocaleContext';

export const LanguageSwitcher = () => {
  const { currentLocale, setCurrentLocale } = useLocale();

  const handleLanguageChange = (locale) => {
    setCurrentLocale(locale);
  };

  return (
    <div className="flex items-center gap-2">
      <button
        onClick={() => handleLanguageChange('en')}
        className={`px-2 py-1 rounded ${
          currentLocale === 'en'
            ? 'bg-gray-100 text-gray-600'
            : 'text-gray-600 hover:bg-gray-100'
        }`}
      >
        EN
      </button>
      <button
        onClick={() => handleLanguageChange('ru')}
        className={`px-2 py-1 rounded ${
          currentLocale === 'ru'
            ? 'bg-gray-100 text-gray-600'
            : 'text-gray-600 hover:bg-gray-100'
        }`}
      >
        RU
      </button>
    </div>
  );
}; 