import { useState } from 'react';
import { PageContainer } from './PageContainer';
import { Header } from './Header';
import { Footer } from './Footer';
import ru from '../locales/ru';
import en from '../locales/en';

const flattenObject = (obj, prefix = '') => {
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : '';
    if (typeof obj[k] === 'object' && obj[k] !== null && !Array.isArray(obj[k])) {
      Object.assign(acc, flattenObject(obj[k], pre + k));
    } else {
      acc[pre + k] = obj[k];
    }
    return acc;
  }, {});
};

export const LocalizationPage = () => {
  const [filter, setFilter] = useState('');
  const flatRu = flattenObject(ru);
  const flatEn = flattenObject(en);
  const keys = Object.keys(flatRu);

  const filteredKeys = keys.filter(key => 
    key.toLowerCase().includes(filter.toLowerCase()) || 
    flatRu[key].toLowerCase().includes(filter.toLowerCase()) ||
    flatEn[key].toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-1 pt-24">
        <PageContainer>
          <div className="max-w-6xl mx-auto">
            <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent mb-8">
              Управление локализациями
            </h1>

            <div className="mb-6">
              <input
                type="text"
                placeholder="Поиск по ключу или значению..."
                className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>

            <div className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg border border-gray-100 overflow-hidden">
              <div className="grid grid-cols-12 gap-4 p-4 bg-gray-50 border-b border-gray-100 font-medium">
                <div className="col-span-4">Ключ</div>
                <div className="col-span-4">Русский</div>
                <div className="col-span-4">English</div>
              </div>
              <div className="divide-y divide-gray-100">
                {filteredKeys.map(key => (
                  <div key={key} className="grid grid-cols-12 gap-4 p-4 hover:bg-gray-50">
                    <div className="col-span-4 font-mono text-sm text-gray-600">{key}</div>
                    <div className="col-span-4">{flatRu[key]}</div>
                    <div className="col-span-4">{flatEn[key]}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </PageContainer>
      </div>
    </div>
  );
}; 