import { 
  CloudIcon, 
  CommandLineIcon,
  GlobeAltIcon
} from '@heroicons/react/24/outline';
import { PageContainer } from './PageContainer';
import { Header } from './Header';
import { Footer } from './Footer';
import { useLocale } from '../contexts/LocaleContext';

export const HelpPage = () => {
  const { t } = useLocale();

  return (
    <div>
      <PageContainer>
        <main className="container mx-auto px-4 py-8">
          <div className="max-w-4xl mx-auto space-y-8">
            {/* Общая информация */}
            <section className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg border border-gray-100 p-6">
              <h2 className="text-xl font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent flex items-center gap-2 mb-4">
                <CloudIcon className="w-6 h-6 text-blue-500" />
                {t('help.sections.overview.title')}
              </h2>
              <div className="prose prose-blue max-w-none">
                <p>{t('help.sections.overview.description')}</p>
                <ul>
                  {t('help.sections.overview.items', { returnObjects: true }).map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
                <h3>{t('help.sections.overview.howItWorks.title')}</h3>
                <p>
                  {t('help.sections.overview.howItWorks.steps', { returnObjects: true }).map((step, index) => (
                    <span key={index}>
                      {index + 1}. {step}<br />
                    </span>
                  ))}
                </p>
              </div>
            </section>

            {/* Настройка клиентов */}
            <section className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-semibold text-gray-900 flex items-center gap-2 mb-4">
                <CommandLineIcon className="w-6 h-6 text-blue-500" />
                {t('help.sections.clients.title')}
              </h2>
              <div className="space-y-6">
                {/* ddclient */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-2">
                    {t('help.sections.clients.ddclient.title')}
                  </h3>
                  <div className="bg-gray-800 rounded-lg p-4">
                    <pre className="text-sm text-white">
                      {t('help.sections.clients.ddclient.config')}
                    </pre>
                  </div>
                </div>

                {/* Synology NAS */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-2">
                    {t('help.sections.clients.synology.title')}
                  </h3>
                  <div className="space-y-2">
                    {t('help.sections.clients.synology.description', { returnObjects: true }).map((line, index) => (
                      <p key={index} className="text-gray-600">{line}</p>
                    ))}
                    <div className="bg-gray-800 rounded-lg p-4">
                      <ul className="list-disc list-inside text-white ml-2 text-sm space-y-1">
                        {t('help.sections.clients.synology.fields', { returnObjects: true }).map((field, index) => (
                          <li key={index}>{field}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Интеграция с собственным доменом */}
            <section className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-semibold text-gray-900 flex items-center gap-2 mb-4">
                <GlobeAltIcon className="w-6 h-6 text-blue-500" />
                {t('help.sections.customDomain.title')}
              </h2>
              <div className="prose prose-blue max-w-none">
                <p>{t('help.sections.customDomain.description')}</p>
                <div className="bg-gray-800 rounded-lg p-4 my-4">
                  <pre className="text-sm text-white">
                    {t('help.sections.customDomain.example')}
                  </pre>
                </div>
                <p>{t('help.sections.customDomain.result')}</p>
                <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 my-4">
                  <p className="text-sm text-yellow-700">
                    <strong>{t('help.sections.customDomain.note.title')}</strong>{' '}
                    {t('help.sections.customDomain.note.text')}
                  </p>
                </div>
              </div>
            </section>
          </div>
        </main>
      </PageContainer>
    </div>
  );
}; 