import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export const useNotifications = () => {
  const { t, i18n } = useTranslation();
  
  const defaultOptions = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  };
  
  return {
    success: (key, options = {}) => 
      toast.success(i18n.isInitialized ? t(key) : key, { ...defaultOptions, ...options }),
      
    error: (key, options = {}) => 
      toast.error(i18n.isInitialized ? t(key) : key, { ...defaultOptions, ...options }),
      
    warning: (key, options = {}) => 
      toast.warning(i18n.isInitialized ? t(key) : key, { ...defaultOptions, ...options }),
      
    info: (key, options = {}) => 
      toast.info(i18n.isInitialized ? t(key) : key, { ...defaultOptions, ...options })
  };
}; 