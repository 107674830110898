import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ADMIN_UID } from '../constants/auth';

export const AdminRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user || user.uid !== ADMIN_UID) {
    return <Navigate to="/hosts" />;
  }

  return children;
}; 