import { 
  RocketLaunchIcon, 
  UserGroupIcon,
  GlobeAltIcon,
  ShieldCheckIcon,
  ServerIcon,
  ChartBarIcon
} from '@heroicons/react/24/outline';
import { Header } from './Header';
import { Footer } from './Footer';
import { PageContainer } from './PageContainer';
import { useLocale } from '../contexts/LocaleContext';
import { Link } from 'react-router-dom';

export const AboutPage = () => {
  const { t } = useLocale();

  return (
    <div>
      <PageContainer>
        <main className="container mx-auto px-4 py-8">
          <div className="max-w-4xl mx-auto space-y-12">
            {/* Основной раздел */}
            <section className="bg-white rounded-lg shadow-md p-8">
              <div className="prose prose-blue max-w-none">
                <h1 className="flex items-center gap-3 text-3xl font-bold text-gray-900 mb-6">
                  <RocketLaunchIcon className="w-8 h-8 text-blue-500" />
                  {t('about.hero.title')}
                </h1>
                
                <p className="text-lg text-gray-600 mb-8">
                  {t('about.hero.description')}
                </p>

                {/* Статистика */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
                  <div className="bg-blue-50 rounded-lg p-4 text-center">
                    <div className="text-3xl font-bold text-blue-600 mb-1">{t('about.stats.users.value')}</div>
                    <div className="text-sm text-gray-600">{t('about.stats.users.label')}</div>
                  </div>
                  <div className="bg-blue-50 rounded-lg p-4 text-center">
                    <div className="text-3xl font-bold text-blue-600 mb-1">{t('about.stats.uptime.value')}</div>
                    <div className="text-sm text-gray-600">{t('about.stats.uptime.label')}</div>
                  </div>
                  <div className="bg-blue-50 rounded-lg p-4 text-center">
                    <div className="text-3xl font-bold text-blue-600 mb-1">{t('about.stats.countries.value')}</div>
                    <div className="text-sm text-gray-600">{t('about.stats.countries.label')}</div>
                  </div>
                </div>

                {/* Преимущества */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
                  <div className="flex items-baseline gap-4">
                    <div className="flex-shrink-0">
                      <GlobeAltIcon className="w-5 h-5 text-blue-500" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900 mb-2">
                        {t('about.features.infrastructure.title')}
                      </h3>
                      <p className="text-gray-600">
                        {t('about.features.infrastructure.description')}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-baseline gap-4">
                    <div className="flex-shrink-0">
                      <ShieldCheckIcon className="w-5 h-5 text-blue-500" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900 mb-2">
                        {t('about.features.security.title')}
                      </h3>
                      <p className="text-gray-600">
                        {t('about.features.security.description')}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-baseline gap-4">
                    <div className="flex-shrink-0">
                      <ServerIcon className="w-5 h-5 text-blue-500" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900 mb-2">
                        {t('about.features.integration.title')}
                      </h3>
                      <p className="text-gray-600">
                        {t('about.features.integration.description')}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-baseline gap-4">
                    <div className="flex-shrink-0">
                      <ChartBarIcon className="w-5 h-5 text-blue-500" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900 mb-2">
                        {t('about.features.monitoring.title')}
                      </h3>
                      <p className="text-gray-600">
                        {t('about.features.monitoring.description')}
                      </p>
                    </div>
                  </div>
                </div>

                {/* О команде */}
                <div className="border-t border-gray-200 pt-8">
                  <div className="flex items-baseline gap-3 mb-6">
                    <UserGroupIcon className="w-6 h-6 text-blue-500" />
                    <h2 className="text-2xl font-bold text-gray-900">{t('about.team.title')}</h2>
                  </div>
                  <p className="text-gray-600 mb-6">
                    {t('about.team.description')}
                  </p>
                  <div className="bg-blue-50 rounded-lg p-6">
                    <blockquote className="text-gray-600 italic">
                      {t('about.team.quote')}
                    </blockquote>
                    <div className="mt-4 text-sm text-gray-500">
                      {t('about.team.signature')}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Призыв к действию */}
            <section className="bg-gradient-to-r from-blue-600 to-blue-700 rounded-lg shadow-md p-8 text-center">
              <h2 className="text-2xl font-bold text-white mb-4">
                {t('about.cta.title')}
              </h2>
              <p className="text-blue-100 mb-6">
                {t('about.cta.description')}
              </p>
              <Link 
                to="/login" 
                className="inline-block bg-white text-blue-600 px-6 py-3 rounded-lg font-medium hover:bg-blue-50 transition-colors"
              >
                {t('about.cta.button')}
              </Link>
            </section>
          </div>
        </main>
      </PageContainer>
    </div>
  );
}; 