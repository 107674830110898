import { Footer } from './Footer';

export const BaseLayout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <div className="flex-1 flex flex-col w-full">
        {children}
      </div>
      <Footer />
    </div>
  );
}; 