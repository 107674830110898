import { useParams } from 'react-router-dom';
import { 
  CalendarIcon, 
  UserIcon,
  TagIcon,
  ArrowLeftIcon
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { PageContainer } from './PageContainer';
import { useLocale } from '../contexts/LocaleContext';

export const BlogPostPage = () => {
  const { slug } = useParams();
  const { t } = useLocale();

  // Получаем данные статьи из списка постов
  const posts = t('blog.posts', { returnObjects: true });
  const post = Array.isArray(posts) ? posts.find(p => p.slug === slug) : null;

  // Получаем контент статьи
  const content = t(`blogContent.${slug}`, { returnObjects: true });

  // Отладочная информация
  // console.log('Slug:', slug);
  // console.log('Post:', post);
  // console.log('Content:', content);
  // console.log('All posts:', posts);
  // console.log('All content:', t('blogContent', { returnObjects: true }));

  if (!post || !content) {
    return (
      <PageContainer>
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-2xl font-bold text-gray-900">
            Post not found
          </h1>
          <p className="text-gray-600 mt-2">
            Slug: {slug}<br />
            Post found: {post ? 'Yes' : 'No'}<br />
            Content found: {content ? 'Yes' : 'No'}
          </p>
          <Link 
            to="/blog"
            className="inline-flex items-center text-blue-600 hover:text-blue-800 mt-4"
          >
            <ArrowLeftIcon className="w-4 h-4 mr-2" />
            {t('blog.backToBlog')}
          </Link>
        </div>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <div className="max-w-4xl mx-auto">
        {/* Навигация */}
        <Link 
          to="/blog"
          className="inline-flex items-center text-gray-600 hover:text-blue-600 mb-8"
        >
          <ArrowLeftIcon className="w-4 h-4 mr-2" />
          {t('blog.backToBlog')}
        </Link>

        {/* Заголовок статьи */}
        <div className="mb-8">
          <div className="inline-flex items-center px-3 py-1 rounded-full bg-blue-50 text-blue-600 text-sm font-medium mb-4">
            <span>{post.category}</span>
          </div>
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            {content.title}
          </h1>
          <div className="flex items-center gap-4 text-gray-500">
            <div className="flex items-center gap-1">
              <UserIcon className="w-4 h-4" />
              <span>{post.author}</span>
            </div>
            <div className="flex items-center gap-1">
              <CalendarIcon className="w-4 h-4" />
              <time dateTime={post.date}>
                {new Date(post.date).toLocaleDateString(t('common.locale'), {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric'
                })}
              </time>
            </div>
            <span>{post.readTime}</span>
          </div>
        </div>

        {/* Изображение */}
        {content.image && (
          <div className="mb-8">
            <img 
              src={content.image.url} 
              alt={content.image.alt}
              className="w-full rounded-xl shadow-lg"
            />
            <p className="text-sm text-gray-500 mt-2">
              {content.image.copyright}
            </p>
          </div>
        )}

        {/* Контент статьи */}
        <article className="prose prose-blue max-w-none">
          <div dangerouslySetInnerHTML={{ __html: content.content }} />
        </article>

        {/* Теги */}
        <div className="mt-8 pt-8 border-t border-gray-100">
          <div className="flex flex-wrap gap-2">
            {post.tags.map((tag) => (
              <span 
                key={tag}
                className="inline-flex items-center text-sm text-gray-600 bg-gray-100 px-3 py-1 rounded-full"
              >
                #{tag}
              </span>
            ))}
          </div>
        </div>
      </div>
    </PageContainer>
  );
}; 