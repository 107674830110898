import { useState, useEffect } from 'react';
import { useLocale } from '../contexts/LocaleContext';
import { useAuth } from '../contexts/AuthContext';
import { useNotifications } from '../services/notifications';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { 
  ClipboardIcon, 
  TrashIcon, 
  PencilIcon,
  GlobeAltIcon,
  ClockIcon,
  ComputerDesktopIcon,
  CheckIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import debug from '../utils/debug';

export const HostCard = ({ host, onDeleteHost, onUpdate }) => {
  const { t } = useLocale();
  const { i18n } = useTranslation();
  const { user } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [newIp, setNewIp] = useState(host.ip);
  const [currentIp, setCurrentIp] = useState(host.ip);
  const [ipError, setIpError] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { success, error, info } = useNotifications();

  useEffect(() => {
    setCurrentIp(host.ip);
    setNewIp(host.ip);
  }, [host.ip]);

  const validateIpv4 = (value) => {
    if (!value) return 'IP адрес обязателен';

    const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/;
    if (!ipv4Regex.test(value)) {
      return 'Неверный формат IP адреса';
    }

    const octets = value.split('.');
    const isValid = octets.every(octet => {
      const num = parseInt(octet, 10);
      return num >= 0 && num <= 255;
    });

    if (!isValid) {
      return 'Каждое число должно быть от 0 до 255';
    }

    return '';
  };

  const handleCopyIp = async () => {
    try {
      await navigator.clipboard.writeText(host.ip);
      setIsCopied(true);
      // console.log('Translation key value:', t('notifications.ipCopied'));
      // success(t('notifications.ipCopied'));
      toast.success('IP address copied');
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      error('errors.copyFailed');
    }
  };

  const handleIpChange = (e) => {
    const value = e.target.value;
    setNewIp(value);
    setIpError(validateIpv4(value));
  };

  const handleSubmitIp = async (e) => {
    e.preventDefault();
    const validationError = validateIpv4(newIp);
    if (validationError) {
      error('errors.invalidIp');
      return;
    }

    setIsUpdating(true);

    try {
      const response = await fetch('/updatehost', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          hostname: host.name,
          ip: newIp,
          uid: user.uid
        })
      });

      if (!response.ok) {
        throw new Error(await response.text() || 'Failed to update IP');
      }

      setCurrentIp(newIp);
      await onUpdate(host.id, newIp);
      setIsEditing(false);
    } catch (err) {
      error('errors.updateFailed');
    } finally {
      setIsUpdating(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    
    // Преобразуем строку ddmmyyyy в формат dd.mm.yyyy
    const day = dateString.substring(0, 2);
    const month = dateString.substring(2, 4);
    const year = dateString.substring(4, 8);
    
    return `${day}.${month}.${year}`;
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await fetch('/delhost', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },
        body: JSON.stringify({
          hostname: host.name,
          uid: user.uid
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to delete host');
      }
      
      await onDeleteHost(host.id);
      // success('notifications.hostDeleted');
    } catch (err) {
      error('errors.deleteFailed');
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden border border-gray-100 relative">
      {isDeleting && (
        <div className="absolute inset-0 bg-white/50 backdrop-blur-sm flex items-center justify-center z-10">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-500" />
        </div>
      )}
      
      <div className="p-6 space-y-6">
        <div className="flex justify-between items-start gap-2">
          <div className="space-y-1.5 min-w-0">
            <div className="flex items-center gap-2.5 min-w-0">
              <ComputerDesktopIcon className="w-5 h-5 text-blue-500 flex-shrink-0" />
              <div className="min-w-0 overflow-hidden">
                <h3 className="text-lg font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent truncate">
                  {host.name}
                  <span className="text-sm font-normal text-gray-500 ml-2">
                    .dynip.space
                  </span>
                </h3>
              </div>
            </div>
          </div>
          <button
            onClick={handleDelete}
            disabled={isDeleting}
            className="text-gray-400 hover:text-red-500 transition-colors p-1 flex-shrink-0"
            title={t('tooltips.hosts.delete')}
          >
            {isDeleting ? (
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-red-500" />
            ) : (
              <TrashIcon className="w-5 h-5" />
            )}
          </button>
        </div>

        <div className="space-y-3">
          <div className="flex items-center gap-2 text-sm font-medium text-gray-600">
            <GlobeAltIcon className="w-4 h-4" />
            <span>{t('hosts.card.ipAddress')}</span>
          </div>
          
          {isEditing ? (
            <form onSubmit={handleSubmitIp} className="flex items-center gap-2">
              <input
                type="text"
                value={newIp}
                onChange={handleIpChange}
                className={`flex-1 px-3 py-1.5 border rounded-lg text-sm ${
                  ipError ? 'border-red-300' : 'border-gray-300'
                }`}
                disabled={isUpdating}
              />
              <div className="flex items-center gap-1">
                <button
                  type="submit"
                  className="p-1.5 text-green-600 hover:text-green-700 disabled:opacity-50"
                  disabled={!!ipError || isUpdating}
                  title={t('tooltips.hosts.save')}
                >
                  <CheckIcon className="w-5 h-5" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsEditing(false);
                    setIpError('');
                  }}
                  className="p-1.5 text-gray-400 hover:text-gray-500"
                  disabled={isUpdating}
                  title={t('tooltips.hosts.cancel')}
                >
                  <XMarkIcon className="w-5 h-5" />
                </button>
              </div>
            </form>
          ) : (
            <div className="flex items-center gap-3">
              <div 
                className="flex items-center gap-2 cursor-pointer group"
                onClick={() => setIsEditing(true)}
                title={t('tooltips.hosts.edit')}
              >
                <span className="text-gray-900 font-medium break-all">
                  {currentIp}
                </span>
                <PencilIcon className="w-4 h-4 text-gray-400 group-hover:text-blue-500" />
              </div>
              <button
                onClick={handleCopyIp}
                className="text-gray-400 hover:text-blue-500 transition-colors p-1"
                title={isCopied ? t('tooltips.hosts.copied') : t('tooltips.hosts.copyIp')}
              >
                {isCopied ? <CheckIcon className="w-4 h-4" /> : <ClipboardIcon className="w-4 h-4" />}
              </button>
            </div>
          )}
        </div>

        <div className="flex items-center gap-2 text-sm text-gray-500 pt-2">
          <ClockIcon className="w-4 h-4" />
          <span>{t('hosts.card.lastUpdate')}</span>
          <time 
            dateTime={host.lastUpdate} 
            className="text-gray-900 font-medium"
          >
            {formatDate(host.lastUpdate)}
          </time>
        </div>
      </div>

      {isUpdating && (
        <div className="absolute inset-0 bg-white/50 backdrop-blur-sm flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      )}
    </div>
  );
}; 