import { createContext, useContext, useState } from 'react';
import ru from '../locales/ru';
import en from '../locales/en';

const LocaleContext = createContext();

const locales = {
  ru,
  en
};

export const LocaleProvider = ({ children }) => {
  const [currentLocale, setCurrentLocale] = useState('en');

  const t = (key, options = {}) => {
    const keys = key.split('.');
    let value = locales[currentLocale];
    
    for (const k of keys) {
      if (value === undefined) break;
      value = value[k];
    }

    if (value === undefined) {
      console.warn(`Translation key not found: ${key}`);
      return key;
    }

    if (options.returnObjects) {
      return value;
    }

    return String(value);
  };

  return (
    <LocaleContext.Provider value={{ currentLocale, setCurrentLocale, t }}>
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocale = () => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }
  return context;
}; 